<template>
  <div class="mainwrap newwestlist" v-loading="loading">
        <div class="title">
            最新消息
        </div>

        <div class="searchform">
            <el-form
                label-width="80px"
                :inline="true"
                :model="searchForm"
                class="demo-form-inline"
            >
                <el-form-item label="標題：">
                    <el-input v-model="searchForm.title" placeholder="請輸入標題"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSearch">查询</el-button>
                    <el-button @click="reSet">重置</el-button>
                </el-form-item>
            </el-form>
        </div>

        <div class="tablewrap">
            <el-table :data="tableData" style="width: 100%" stripe>
                <el-table-column prop="id" label="ID" width="220"></el-table-column>
                <el-table-column prop="title" label="標題"></el-table-column>
                <el-table-column label="選中狀態" width="520">
                    <template slot-scope="scope">
                        <div class="switchbox">
                            <el-switch
                                v-model="scope.row.isChecked"
                                @change="changeStatus(scope.row)"
                                active-color="#13ce66"
                                inactive-color="#e4e7ed"
                                :disabled="scope.row.isDisabled || permissionType !== 2"
                                >
                            </el-switch>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="pagination">
            <el-pagination
            background
            :hide-on-single-page="isSinglePage"
            layout="prev, pager, next,jumper"
            @current-change="handleCurrentChange"
            :page-size="pageSize"
            :current-page="currentPage"
            :total="tableDataTotal"
            >
            </el-pagination>
        </div>

        <!-- <el-row>
            <el-col :span="16">
                <div class="left">
                    <p class="subtitle">
                        <span>
                            所有消息
                        </span>
                    </p>
                    <el-table :data="newsData" @selection-change="handleSelectionChange" row-key="key" ref="multipleTable">
                        <el-table-column type="selection" width="100" :reserve-selection="true"></el-table-column>
                        <el-table-column prop="" label="名稱"></el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                        background
                        :hide-on-single-page="isSinglePage"
                        layout="prev, pager, next,jumper"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :current-page="currentPage"
                        :total="tableDataTotal"
                        >
                        </el-pagination>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="right">
                    <p class="subtitle">
                        <span>已選中</span>
                    </p>
                    <el-table :data="checkedNews" style="width: 100%" stripe>
                        <el-table-column prop="" label="名稱"></el-table-column>
                        <el-table-column label="操作" width="220">
                            <template slot-scope="scope">
                                <div class="delbtn">
                                    <el-popconfirm
                                        confirm-button-text='確定'
                                        cancel-button-text='取消'
                                        confirm-button-type="danger"
                                        icon="el-icon-info"
                                        icon-color="red"
                                        title="確定刪除？"
                                        @confirm="delItem(scope.row.id)"
                                        >
                                        <el-button type="danger" slot="reference">刪除</el-button>
                                    </el-popconfirm>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div class="pagination">
                        <el-pagination
                        background
                        :hide-on-single-page="isSinglePage2"
                        layout="prev, pager, next"
                        @current-change="handleCurrentChange"
                        :page-size="pageSize"
                        :current-page="currentPage"
                        :total="tableDataTotal"
                        >
                        </el-pagination>
                    </div>
                </div>
            </el-col>
        </el-row> -->
  </div>
</template>

<script>
import * as api from "./api";
import { getStore} from '@/utils/storage';
export default {
  name: 'newestInfo',
    data(){
        return{
          loading:false,
          searchForm:{
              title:""
          },
          multipleSelection:[],
          tableData:[],
          checkedNews:[],
          isSinglePage:false,
          currentPage:1,
          pageSize:10,
          tableDataTotal:0,
          permissionType: -1
        }
    },
    created(){
      this.getNewsList();
      this.permissionType = parseInt(getStore("permissionType"));
    },
    methods:{
      getNewsList(){
        let params={
          title:this.searchForm.title,
          page:this.currentPage,
          limit:this.pageSize,
        }
        this.loading=true;
        api.getNewsList(params).then(res=>{
          if(res.systemCode===200){
            this.tableData=res.data.items.map(item=>{
                return {
                  ...item,
                  isDisabled:false
                }
            });
            this.tableDataTotal=res.data.totalCount;
          }
          this.loading=false;
        })
      },
      onSearch(){
        this.currentPage=1;
        this.getNewsList();
      },
      reSet(){
        this.currentPage=1;
        this.searchForm={
          title:""
        }
        this.getNewsList();
      },
      handleSelectionChange(val){
        this.multipleSelection = val;
      },
      handleCurrentChange(val){
        this.currentPage=val;
        this.getNewsList();
      },
      changeStatus(item){
        if (this.permissionType===2){
          item.isDisabled=true;
          let params=`/${item.id}/select-news/${item.categoryId}?isChecked=${item.isChecked}`
          api.selectNews(params).then(res=>{
            if(res.systemCode===200){
              this.$message.success('修改成功');
              item.isDisabled=false;
            }
          })
        }

      }
    }
}
</script>

<style lang="scss" scoped>
.newwestlist {
  .searchform {
    padding: 20px;
    border-bottom: 1px solid #ccc;
  }
  .left,.right{
      margin-top: 20px;
      padding: 20px;
      border: 1px #ccc solid;
      border-radius: 10px;
      .subtitle{
          display: inline-block;
          border-bottom: 3px #409EFF solid;
          padding: 5px 10px;
          font-weight: 600;
          margin-bottom: 8px;
      }
  }
  .left{
      margin-right: 20px;
  }
  .delbtn{
    display: inline-block;
    margin-left: 10px;
  }
}
</style>
