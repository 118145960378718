import { getRequest,getStrRequest,postRequest,postFormReq,deleteRequest } from "@/http/request_service.js";
import { API } from "@/api/api.js"

// 獲取列表
export const getNewsList=(params)=>{
    return getRequest(API.newsList,params);
}
// 選中
export const selectNews=(params)=>{
    return postRequest(API.selectNews+params);
}